// Dynamic path for an entity or product page detail

import React from 'react';
import { Router } from '@reach/router';
import DetailedEntityPage from 'views/DetailedEntityPage';
import DetailedSolutionPage from 'views/DetailedSolutionPage';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import DashboardLayout from 'layouts/DashboardLayout';

// Components to be updated with the right component for the layout

export default function App(props) {
	return (
		<Router basepath={`/app`}>
			<WithLayout
				component={DetailedEntityPage}
				layout={DashboardLayout}
				path={`/dashboard`}
				location={props.location}
			/>
			<WithLayout
				component={DetailedEntityPage}
				layout={DashboardLayout}
				path={`/mybrand`}
				location={props.location}
			/>
			<WithLayout
				component={DetailedEntityPage}
				layout={DashboardLayout}
				path={`/savedsolutions`}
				location={props.location}
			/>
			<WithLayout
				component={DetailedEntityPage}
				layout={DashboardLayout}
				path={`/mysolutions`}
				location={props.location}
			/>
		</Router>
	);
}
