import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import styled from 'styled-components';

const DashboardLayoutRoot = styled.div`
    backgroundColor: ${props => `${props.theme.palette.background.default}`},
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  `;

const DashboardLayoutWrapper = styled.div`
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: ${props => `64,
  [${props.theme.breakpoints.up('lg')}]: {
    paddingLeft: 256,
  }`}
  `;

const DashboardLayoutContainer = styled.div`
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
`;

const DashboardLayoutContent = styled.div`
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
`;

interface Props {
  children: React.ReactNode;
  themeToggler: Function;
  themeMode: string;
}

const DashboardLayout = ({
  children,
  themeToggler,
  themeMode,
}: Props): JSX.Element => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
